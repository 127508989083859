import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { useDisclosure } from '@mantine/hooks';
import { useForm } from '@mantine/form';
import axios from 'axios';
import { Modal, Textarea, Button, Text, Checkbox, Stack, Group, Flex, Title, Select, Box, TextInput, ScrollArea } from '@mantine/core';

import { ActionIcon } from '@mantine/core';
import { IconChevronLeft } from '@tabler/icons-react';

function Home({ pricesLoaded }) {

  useEffect(() => {
    console.log(pricesLoaded);
  }, [pricesLoaded]);

  const navigate = useNavigate();
  
  const form = useForm({
    initialValues: {
      email: '',
      termsOfService: false,
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });
  
  const [opened, { open, close }] = useDisclosure(false);

  const [device, setDevice] = useState(null);

  const submitForm = (data) => {
    console.log('Submit', data);

    axios.post(`https://ar-prijsmodule-app-ozgdj.ondigitalocean.app/api/sendgrid/aanvraag`, data, {
      headers: {
        'Accept': 'application/json'
      }
    })
    .then(function (response) {
      // handle success
      console.log(response);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .finally(function () { 
      // always executed
      console.log('done');
      close();
      setDevice(null);
      navigate(`/bedankt`);
    });
  }

  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      py="xl"
      style={{ minHeight: '100vh', maxWidth: '100vw' }}
    >

    <Modal opened={opened} onClose={close} title="Reparatie aanvragen">
      {/* Modal content */}
      <Box maw={500} mx="auto">
        <form onSubmit={form.onSubmit((values) => submitForm(values))}>

          <Flex
            gap="md"
          >
            <TextInput
              withAsterisk
              label="Toestel"
              placeholder="Toestel"
              mb="sm"
              {...form.getInputProps('toestel')}
            />
            <TextInput
              withAsterisk
              label="Modelnummer"
              placeholder="Modelnummer"
              mb="sm"
              {...form.getInputProps('modelnummer')}
            />
          </Flex>

          <Textarea
            placeholder="Beschrijf je defect(en):"
            label="Beschrijf je defect(en):"
            withAsterisk
            minRows={3}
            mb="sm"
            {...form.getInputProps('comments')}
          />

          <Flex
            gap="md"
          >
            <TextInput
              withAsterisk
              label="Voornaam"
              placeholder="Voornaam"
              mb="sm"
              {...form.getInputProps('voornaam')}
            />
            <TextInput
              withAsterisk
              label="Achternaam"
              placeholder="Achternaam"
              mb="sm"
              {...form.getInputProps('achternaam')}
            />
          </Flex>

          <Flex
            gap="md"
          >
            <TextInput
              withAsterisk
              label="Telefoonnummer"
              placeholder="Telefoonnummer"
              mb="sm"
              {...form.getInputProps('telefoon')}
            />
            <TextInput
              withAsterisk
              label="E-mailadres"
              placeholder="E-mailadres"
              mb="sm"
              {...form.getInputProps('email')}
            />
          </Flex>

          <Group position="right" mt="md">
            <Button type="submit">Aanvragen</Button>
          </Group>
        </form>
      </Box>
        
    </Modal>

      <Text fz="xl" ta="center" onClick={() => setDevice(null)} style={{
          background: '#eee',
          padding: '3px',
          borderRadius: '4px',
          color: '#333',
          marginBottom: '30px'
        }}>
        <ActionIcon style={{
          color: '#333'
        }}>
          <IconChevronLeft size="1.125rem" />
        </ActionIcon>
      </Text>
      <Text fz="xl" ta="center">Reparatie</Text>
      <Title order={2} mb="xl">Kies het type toestel</Title>

      { device && device === 'laptop' ?
        <Flex
          gap="xl"
          mt="xl"
          direction={{ base: 'column', lg: 'row' }}
        >
          <Box className="toestel-kiezen-box" p="lg" onClick={open}>
            <img src="https://f.hubspotusercontent30.net/hubfs/7664381/Amstelveen%20Repair/assets/img/apple-logo.png" alt="" />
          </Box>
          <Box className="toestel-kiezen-box" p="lg" onClick={open}>
            <img src="https://f.hubspotusercontent30.net/hubfs/7664381/Amstelveen%20Repair/assets/img/windows-logo.png" alt="" />
          </Box>
        </Flex>
      :
        device && device === 'tablet' ?
        <Flex
          gap="xl"
          mt="xl"
          direction={{ base: 'column', lg: 'row' }}
        >
          <Link to={`/repairs/ipad`}>
            <Box className="toestel-kiezen-box" p="lg">
              <img src="https://f.hubspotusercontent30.net/hubfs/7664381/Amstelveen%20Repair/assets/img/apple-logo.png" alt="" />
            </Box>
          </Link>
          <Box className="toestel-kiezen-box" p="lg" onClick={open}>
            <img src="https://f.hubspotusercontent30.net/hubfs/7664381/Amstelveen%20Repair/assets/img/samsung-logo.svg" alt="" />
          </Box>
        </Flex>
        :
        device && device === 'telefoon' ?
        <Flex
          gap="xl"
          mt="xl"
          direction={{ base: 'column', lg: 'row' }}
        >
          <Link to={`/repairs/iphone`}>
            <Box className="toestel-kiezen-box" p="lg">
              <img src="https://f.hubspotusercontent30.net/hubfs/7664381/Amstelveen%20Repair/assets/img/apple-logo.png" alt="" />
            </Box>
          </Link>
          <Box className="toestel-kiezen-box" p="lg" onClick={open}>
            <img src="https://f.hubspotusercontent30.net/hubfs/7664381/Amstelveen%20Repair/assets/img/samsung-logo.svg" alt="" />
          </Box>
        </Flex>
        :
        <Flex
          gap="xl"
          mt="xl"
          direction={{ base: 'column', lg: 'row' }}
        >
          <Box className="toestel-kiezen-box" p="lg" onClick={() => setDevice('telefoon')}>
            <img src="https://f.hubspotusercontent30.net/hubfs/7664381/Amstelveen%20Repair/assets/img/iphone-icon.png" alt="" />
            <Text fz="md" ta="center">Telefoon</Text>
          </Box>
          <Box className="toestel-kiezen-box" p="lg" onClick={() => setDevice('tablet')}>
            <img src="https://f.hubspotusercontent30.net/hubfs/7664381/Amstelveen%20Repair/assets/img/ipad-icon.png" alt="" />
            <Text fz="md" ta="center">Tablet</Text>
          </Box>
          <Box className="toestel-kiezen-box" p="lg" onClick={() => setDevice('laptop')}>
            <img src="https://f.hubspotusercontent30.net/hubfs/7664381/Amstelveen%20Repair/assets/img/laptop-icon.png" alt="" />
            <Text fz="md" ta="center">Laptop</Text>
          </Box>
        </Flex>
      }

    </Flex>
  );
}
 
export default Home;