import { useEffect, useState, useCallback } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom'
import { useDisclosure } from '@mantine/hooks';
import { Modal, Button, Text, Checkbox, Textarea, Group, Flex, Title, Select, Box, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import axios from 'axios';

import { ActionIcon } from '@mantine/core';
import { IconChevronLeft } from '@tabler/icons-react';

import { DataTable } from 'mantine-datatable';

function Repairs({ items, prices, toestel }) {
  
  const form = useForm({
    initialValues: {
      email: '',
      toestel: '',
      termsOfService: false,
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  const navigate = useNavigate();

  const [opened, { open, close }] = useDisclosure(false);
  const modal1 = useDisclosure()

  const [filteredItems, setFilteredItems] = useState([]);
  const [itemsOutput, setItemsOutput] = useState([]);
  const [uniqueItems, setUniqueItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);

  const [device, setDevice] = useState(null);

  let { slug } = useParams();

  const getUnique = () => {
    console.log('All items: ', items);
    const unique = [...new Set(items.map(item => item.Toestel))]; 

    let uniqueForDevice = unique.filter(item => item.toLowerCase().includes(toestel));
    console.log('uniqueeeeeeeee', uniqueForDevice)
    uniqueForDevice = uniqueForDevice.reverse();

    setUniqueItems(uniqueForDevice);
  }

  const filterItems = async (slug) => {
    console.log(slug);
    let newItems = [];

    await items.forEach(item => {
      let itemSlug = (item.Toestel).toLowerCase().replaceAll(' ', '-').replaceAll('.', '').replaceAll(',', '-').replaceAll('"', '');

      if (itemSlug === slug) {
        newItems.push(item);
      }
    })
    
    return newItems;
  };
  
  const filterOutput = async (filteredItems, filteredPrices) => {

    let itemsOutput = [];
    console.log('fffffffff', filteredItems);
    console.log('ffffffffwe323232f', filteredPrices);

    await filteredItems.map((item, index) => { 
      
      const description = `${item.Toestel} - ${item.Onderdeel}`;

      let price = filteredPrices.filter(price => (price.description).replaceAll(',', '.') === description);

      filteredPrices.forEach(price => {
        console.log(description);
        console.log(price.description);
      })

      console.log('ppppppppppppp', price);
      
      if (!price[0]) return false;
      

      price = price[0].Prices.ItemPrice[0].amount;

      itemsOutput.push({
        "checked": false,
        "id": index - 1,
        "toestel": item.Toestel,
        "onderdeel": item.Onderdeel,
        "price": price
      })
    })
    console.log('; Setting items output', itemsOutput);

    setItemsOutput(itemsOutput);
    
    form.setFieldValue('toestel', itemsOutput[0].toestel);
  }

  useEffect(() => {

    getUnique();

    filterItems(slug).then((value) => {
      console.log('vallll', value);
      setItemsOutput(value);
      
      const filteredPrices = prices.filter(price => (price.description.toLowerCase().replaceAll(' ', '-').replaceAll('.', '').replaceAll(',', '').replaceAll('"', '')).includes(`${slug}-`));

      prices.forEach(price => {
        if (price.description.includes('Pro 10')) {
          console.log(`${slug}-`)
          console.log(price.description.toLowerCase().replaceAll(' ', '-').replaceAll('.', '').replaceAll(',', '').replaceAll('"', ''))
        }
      })
      
      console.log('pfpreorerore', filteredPrices);

      filteredPrices.forEach(price => {
        if ((price.description.toLowerCase().replaceAll(' ', '-').replaceAll('.', '').replaceAll(',', '').replaceAll('"', '')).includes(`${slug}-`)) {
          console.log(slug);
        }
      })

      filterOutput(value, filteredPrices);
    });

  }, [slug, items]);

  const submitForm = (data) => {
    console.log('Submit', data);

    const repairs = itemsOutput.filter(item => item.checked !== false);
    console.log(repairs);

    data.repairs = repairs;

    axios.post(`https://ar-prijsmodule-app-ozgdj.ondigitalocean.app/api/sendgrid/aanvraag`, data, {
      headers: {
        'Accept': 'application/json'
      }
    })
    .then(function (response) {
      // handle success
      console.log(response);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .finally(function () {
      // always executed
      console.log('done');
      close();
      setDevice(null);
      navigate(`/bedankt`);
    });
  }

  const handleChange = (id) => {

    let oldItems = [...itemsOutput];
    const item = oldItems.filter(a => a.id === id)[0]
    const items = oldItems.filter(a => a.id !== id)

    item.checked = !item.checked;

    items.push(item);

    setItemsOutput(oldItems);

    calculatePrices();
  }

  const calculatePrices = () => {

    let priceTotal = 0;

    itemsOutput.forEach(item => {
      if (item.checked !== false) {
        const itemPrice = parseInt(item.price);
        priceTotal = priceTotal + itemPrice;
      }
    })
    
    setTotalPrice(priceTotal);
  }

  // const changeDevice = async (item) => {

  //   const slug2 = item.toLowerCase().replaceAll(' ', '-')

  //   setDevice({
  //     "slug": slug2,
  //     "toestel": item
  //   });

  //   getUnique();

  //   filterItems(slug2).then((value) => {
  //     setItemsOutput(value);
      
  //     const filteredPrices = prices.filter(price => (price.description.toLowerCase().replaceAll(' ', '-')).includes(`${slug}-`));

  //     filteredPrices.forEach(price => {
  //       if ((price.description.toLowerCase().replaceAll(' ', '-')).includes(`${slug2}-`)) {
  //         console.log(slug);
  //       }
  //     })

  //     filterOutput(value, filteredPrices);
  //   });
  // }

  const selectDevice = (item) => {
    console.log('Select device', item);

    const slug = item.toLowerCase().replaceAll(' ', '-').replaceAll('.', '').replaceAll(',', '-').replaceAll('"', '');

    navigate(`/repairs/${toestel}/${slug}`);
  }
  
  return (
    <>

      {
        slug ?

        <>
          <div className="table">
          
          <Flex
            justify="space-between"
            align="center"
            px="sm"
            style={{ 
              borderTop: '1px solid #eee',
              borderBottom: '1px solid #eee' 
            }}
          >
            <Link to={`/repairs/${toestel}`} style={{
                background: '#eee',
                padding: '3px',
                borderRadius: '4px',
                color: '#333'
              }}>
              <ActionIcon style={{
                color: '#333'
              }}>
                <IconChevronLeft size="1.125rem" />
              </ActionIcon>
            </Link>

            <Title order={2} mx="sm" pb="md" pt="sm">
              { itemsOutput && itemsOutput.length > 0 ? itemsOutput[0].toestel : '' }
            </Title>

            <Link to={`#`} style={{ opacity: '0' }}>
              <ActionIcon>
                <IconChevronLeft size="1.125rem" />
              </ActionIcon>
            </Link>
          </Flex> 

          { itemsOutput && itemsOutput.length > 0 ?
            itemsOutput.map((item, index) => {
              if (item.price === '0') return false;
              return (
                <Flex
                  mih={50}
                  gap="md"
                  justify="space-between"
                  align="center"
                  direction="row"
                  wrap="wrap"
                  mx="sm"
                  onClick={() => handleChange(item.id)}
                >
                  <Flex
                    gap="md"
                    align="center"
                  >
                    <Checkbox
                      checked={ item.checked }
                      readOnly
                    />
                    <Text fz="sm" ta="left">{ item.onderdeel }</Text>
                  </Flex>
                  <Text fz="sm">{ item.price }</Text>
                </Flex>
              )
            })
          : <h2>Loading...</h2> }
          </div>
      
          <div className="sticky-cta">
            <Button onClick={open}>Aanvragen - €{ totalPrice }</Button>
          </div>
      
          <Modal opened={opened} onClose={close} title="Reparatie aanvragen">
            {/* Modal content */}
            <Box maw={500} mx="auto">
              <form onSubmit={form.onSubmit((values) => submitForm(values))}>

                <TextInput
                  withAsterisk
                  label="Toestel"
                  placeholder="Toestel"
                  mb="sm"
                  value={device}
                  onChange={(event) => setDevice(event.currentTarget.value)}
                  readOnly
                  {...form.getInputProps('toestel')}
                />

                <Textarea
                  placeholder="Beschrijf je defect(en)"
                  label="Beschrijf je defect(en)"
                  withAsterisk
                  minRows={3}
                  mb="sm"
                  {...form.getInputProps('comments')}
                />

                <Flex
                  gap="md"
                >
                  <TextInput
                    withAsterisk
                    label="Voornaam"
                    placeholder="Voornaam"
                    mb="sm"
                    {...form.getInputProps('voornaam')}
                  />
                  <TextInput
                    withAsterisk
                    label="Achternaam"
                    placeholder="Achternaam"
                    mb="sm"
                    {...form.getInputProps('achternaam')}
                  />
                </Flex>

                <Flex
                  gap="md"
                >
                  <TextInput
                    withAsterisk
                    label="Telefoonnummer"
                    placeholder="Telefoonnummer"
                    mb="sm"
                    {...form.getInputProps('telefoon')}
                  />
                  <TextInput
                    withAsterisk
                    label="E-mailadres"
                    placeholder="E-mailadres"
                    mb="sm"
                    {...form.getInputProps('email')}
                  />
                </Flex>

                <Group position="right" mt="md">
                  <Button type="submit">Aanvragen - €{ totalPrice }</Button>
                </Group>
              </form>
            </Box>
              
          </Modal>
        </>

        :
        uniqueItems && uniqueItems.length > 0 ?
        <Flex
          align="center"
          justify="center"
          direction="column"
          p="lg"
          style={{ 
            minHeight: '100vh', maxWidth: '100vw'
          }}
        >

          <Link to="/" style={{ color: 'inherit' }} mb="lg">
            <Text fz="xl" ta="center" onClick={() => setDevice(null)} style={{
                background: '#eee',
                padding: '3px',
                borderRadius: '4px',
                color: '#333',
                marginBottom: '30px'
              }}>
              <ActionIcon style={{
                color: '#333'
              }}>
                <IconChevronLeft size="1.125rem" />
              </ActionIcon>
            </Text>
          </Link>
          <Text fz="xl" ta="center">Reparatie</Text>
          <Title order={2} mb="xl">Kies het type toestel</Title>

          <Select
            placeholder="Kies je toestel"
            data={uniqueItems}
            onChange={(item) => selectDevice(item)}
            style={{
              maxWidth: '100%',
              width: '100%'
            }}
          />
        </Flex>
        : null 
      }

    </>
  );
}
 
export default Repairs;