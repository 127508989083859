import { useEffect, useState } from 'react';
import axios from 'axios';

import { Helmet } from 'react-helmet';

import { Routes, Route, Outlet, Link } from "react-router-dom";

import Home from "./pages/Home";
import Repairs from "./pages/Repairs";
import NoPage from "./pages/NoPage";

import './App.css';
import Thanks from './pages/Thanks';
import Loader from './pages/Loader';

function App() {

  const [meta, setMeta] = useState({
    title: 'Default Title',
    description: 'Default Description',
  });

  const [items, setItems] = useState(JSON.parse(sessionStorage.getItem('data')) ? JSON.parse(sessionStorage.getItem('data')) : []);
  const [prices, setPrices] = useState(JSON.parse(sessionStorage.getItem('prices')) ? JSON.parse(sessionStorage.getItem('prices')) : []);
  const [pricesLoaded, setPricesLoaded] = useState(JSON.parse(sessionStorage.getItem('prices')) ? JSON.parse(sessionStorage.getItem('prices')) : false);

  useEffect(() => {
    fetchData();
    fetchPrices();

    setMeta({
      title: 'Laat direct je Apple iPhone repareren bij Amstelveen Repair',
      description: 'Heb je een kapot Apple iPhone? Laat het snel en vakkundig repareren door Amstelveen Repair. Wij zijn gespecialiseerd in het repareren van iPhones en iPads.'
    });
  }, []);

  const fetchData = () => {
    if (sessionStorage.getItem('data')) return false; 

    console.log('Fetching data...');

    axios.get(`https://ar-prijsmodule-app-ozgdj.ondigitalocean.app/api/lightspeed/csv-prices`)
    .then(function (response) {
      // handle success
      let iPhoneItems = response.data.iphones
      let iPadItems = response.data.ipads

      console.log('11111111111111111111', iPhoneItems);
      console.log('22222222222222222222', iPadItems);

      let items = iPhoneItems.concat(iPadItems);

      setItems(items);
      sessionStorage.setItem('data', JSON.stringify(items));
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .finally(function () {
      // always executed
    });
  }
  
  const fetchPrices = () => {
    if (sessionStorage.getItem('prices')) return false; 

    console.log('Fetching prices...');

    axios.get(`https://ar-prijsmodule-app-ozgdj.ondigitalocean.app/api/lightspeed/prices`)
    .then(function (response) {
      // handle success
      const items = response.data.message
      console.log(items);
      setPrices(items);
      sessionStorage.setItem('prices', JSON.stringify(items));

      setPricesLoaded(true);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    }) 
    .finally(function () {
      // always executed
    });
  }

  return (
    <div className="App">

      <Helmet>
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
      </Helmet>

      <Routes>
        <Route path="/" element={<Layout />}>
          {
            pricesLoaded ?
              <Route index element={<Home />} />
            :
              <Route index element={<Loader />} />
          }
          <Route path="repairs" element={<Home />} />
          <Route path="repairs/iphone" element={<Repairs items={items} prices={prices} toestel="iphone" />} />
          <Route path="repairs/iphone/:slug" element={<Repairs items={items} prices={prices} toestel="iphone" />} />
          <Route path="repairs/ipad" element={<Repairs items={items} prices={prices} toestel="ipad" />} />
          <Route path="repairs/ipad/:slug" element={<Repairs items={items} prices={prices} toestel="ipad" />} />
          <Route path="bedankt" element={<Thanks />} />

          {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </div>
  );
}

function Layout() {
  return (
    <div style={{ maxWidth: '500px', margin: '0 auto' }}>
      {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
      <Outlet />
    </div>
  );
}

export default App;
