import { useState } from 'react';
import { Link } from 'react-router-dom'
import { useDisclosure } from '@mantine/hooks';
import { useForm } from '@mantine/form';
import axios from 'axios';
import { Modal, Textarea, Button, Text, Checkbox, Stack, Group, Flex, Title, Select, Box, TextInput, ScrollArea } from '@mantine/core';

function Loader() {

  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      py="xl"
      style={{ minHeight: '100vh', maxWidth: '100vw' }}
    > 

      <img className="rotating" src="https://www.amstelveenrepair.nl/touch-icon-ipad-retina.png" alt="" />
      <Text fz="xl" mt="xl" ta="center">Onze prijzen worden opgehaald...</Text>

    </Flex>
  );
}
 
export default Loader;