import { useState } from 'react';
import { Link } from 'react-router-dom'
import { useDisclosure } from '@mantine/hooks';
import { useForm } from '@mantine/form';
import axios from 'axios';
import { Modal, Textarea, Button, Text, Checkbox, Stack, Group, Flex, Title, Select, Box, TextInput, ScrollArea } from '@mantine/core';

function Thanks() {

  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      py="xl"
      style={{ minHeight: '100vh', maxWidth: '100vw' }}
    >

      <Title order={2}>Bedankt voor je aanvraag.</Title>
      <Text style={{ textAlign: 'center' }} mt="md" fz="md">Er is zojuist een bevestigingsmail verzonden naar je e-mailadres. Wij nemen zo spoedig mogelijk contact met je op.</Text>

      <Group position="right" mt="xl">
        <Link to="/">
          <Button type="submit">Terug naar het beginscherm</Button>
        </Link>
      </Group>

    </Flex>
  );
}
 
export default Thanks;